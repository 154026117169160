import React from "react"
import { Typography } from "@material-ui/core"
class Hero extends React.Component {
  render() {
    const { colorClass, children, CTA, image } = this.props

    return (
      <>
        <div className={["cover", colorClass].join(" ")}>
          {image}
          <div className={"textContainer"}>
            {children}
            {CTA}
          </div>
        </div>
      </>
    )
  }
}

export default Hero
