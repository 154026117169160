import React from "react"

import { Helmet } from "react-helmet"
import { useI18next } from "gatsby-plugin-react-i18next"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, meta, title }) {
  if (!meta) meta = []

  const {
    languages,
    language,
    originalPath,
    defaultLanguage,
    siteUrl = "",
  } = useI18next()

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "Restaurant",
      "@id": "https://bitekitchens.com",
      name: title,
      address: {
        "@type": "PostalAddress",
        streetAddress: "Östra Mårtensgatan 2B",
        addressLocality: "Lund",
        addressRegion: "Skåne",
        postalCode: "22361",
        addressCountry: "SE",
      },
    },
  ]

  return (
    <Helmet
      title={title}
      htmlAttributes={{
        lang: language,
      }}
      //titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          itemprop: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {language !== defaultLanguage ? (
        <link rel="canonical" href="https://bitekitchens.com" />
      ) : (
        languages
          .filter(l => l !== defaultLanguage)
          .map(lng => (
            <link
              rel="alternate"
              key={lng}
              href={`https://bitekitchens.com/${lng}/`}
              hrefLang={lng}
            />
          ))
      )}

      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

export default SEO
