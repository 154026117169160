import React from "react"
import { Button, Grid, Typography, Dialog } from "@material-ui/core"

import { Link, useI18next, Trans } from "gatsby-plugin-react-i18next"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Wave from "../components/SeperatorWave"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import FB from "../images/facebook.svg"
import IG from "../images/instagram.svg"

import {
  ParallaxProvider,
  Parallax,
  ParallaxBanner,
} from "react-scroll-parallax"

const brands = {
  bite: {
    foodoraLink: "https://www.foodora.se/restaurant/new/slne/bite",
    biteLink: "https://bitekitchens.com/order/home",
    name: "Bite",
  },
  smashingBurgers: {
    foodoraLink: "https://www.foodora.se/restaurant/new/d9jg/smashing-burgers",
    biteLink: "https://bitekitchens.com/order/smashing-burgers",
    name: "Smashing Burgers",
  },
  pokiDoki: {
    foodoraLink: "http://foodora.se/restaurant/new/qm2l/pokidoki",
    biteLink: "https://bitekitchens.com/order/poki-doki",
    name: "Poki Doki",
  },
  chickNBuns: {
    foodoraLink: "http://foodora.se/restaurant/new/oece/chick-n-buns",
    biteLink: "https://bitekitchens.com/order/chick-n-buns",
    name: "Chick n' Buns",
  },
  happyRoots: {
    foodoraLink: "http://foodora.se/restaurant/new/hbez/happy-roots",
    biteLink: "https://bitekitchens.com/order/happy-roots",
    name: "Happy Roots",
  },
  wonderWings: {
    foodoraLink: "https://www.foodora.se/restaurant/new/c13q/wonder-wings",
    biteLink: "https://bitekitchens.com/order/wonder-wings",
    name: "Wonder Wings",
  },
  tastySquare: {
    foodoraLink: "http://foodora.se/restaurant/new/y0az/tasty-squared",
    biteLink: "https://bitekitchens.com/order/tasty-square",
    name: "Tasty Square",
  },
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    screenshot: file(relativePath: { eq: "screenshot.png" }) {
      ...app
    }
    download_appstore: file(relativePath: { eq: "download_appstore.png" }) {
      ...image
    }
    download_playstore: file(relativePath: { eq: "download_playstore.png" }) {
      ...image
    }
    blazinLogo: file(relativePath: { eq: "blazinhotchicken.png" }) {
      ...image
    }
    wonderLogo: file(relativePath: { eq: "wonder-wings.png" }) {
      ...image
    }
    templeLogo: file(relativePath: { eq: "templeofwings.png" }) {
      ...image
    }
    chicknbunsPoster: file(
      relativePath: { eq: "posters/chicknbuns-poster.jpg" }
    ) {
      ...image
    }
    happyrootsPoster: file(
      relativePath: { eq: "posters/happyroots-poster.jpg" }
    ) {
      ...image
    }
    pokidokiPoster: file(relativePath: { eq: "posters/pokidoki-poster.jpg" }) {
      ...image
    }
    smashingburgersPoster: file(
      relativePath: { eq: "posters/smashingburgers-poster.jpg" }
    ) {
      ...image
    }
    tastysquarePoster: file(
      relativePath: { eq: "posters/tastysquare-poster.jpg" }
    ) {
      ...image
    }
    wonderwingsPoster: file(
      relativePath: { eq: "posters/wonderwings-poster.jpg" }
    ) {
      ...image
    }
    foodoraLogo: file(relativePath: { eq: "foodora.png" }) {
      ...image
    }
    biteTakeawayLogo: file(relativePath: { eq: "bite-takeaway.png" }) {
      ...image
    }
  }
`

const App = ({ data }) => {
  const { t } = useI18next()
  const images = data
  //const images = useStaticQuery(imageQuery);

  const [openRestaurant, setOpenRestaurant] = React.useState(null)

  return (
    <ParallaxProvider>
      <Layout showBrands={false} colorClass="normalColor">
        <SEO title={t("SEO_title_bite")} description={t("SEO_desc_bite")} />
        <Hero
          colorClass="mainColor"
          image={
            <Parallax className="custom-class" y={[20, -20]} tagOuter="figure">
              <GatsbyImage
                image={images.screenshot.childImageSharp.gatsbyImageData}
                className={"img appScreenshot"}
                alt="app screenshot"
                loading="eager"
              />
            </Parallax>
          }
        >
          <Typography
            variant="h1"
            className={"siteTitle"}
            style={{ marginTop: 20 }}
          >
            {t("App_desc")}
          </Typography>
          <Typography
            variant="h1"
            className={"siteTitle"}
            style={{
              marginTop: 40,
              fontSize: 30,
              fontFamily: "Montserrat",
              fontWeight: 400,
            }}
          >
            Nu i Lund!
          </Typography>
          <Grid container spacing={6} className={"ctas"}>
            {false && (
              <Grid item>
                <Button
                  color="primary"
                  className={"button ctabutton"}
                  variant="outlined"
                  style={{
                    borderColor: "#fcfcfc",
                    color: "#fcfcfc",
                  }}
                  href={"https://bitekitchens.com/giftcard"}
                >
                  {t("Buy giftcard")}
                </Button>
              </Grid>
            )}
            <Grid item>
              <GatsbyImage
                image={images.download_appstore.childImageSharp.gatsbyImageData}
                style={{ width: 140 }}
                className={"img"}
                alt="app screenshot"
                loading="eager"
              />
            </Grid>
            <Grid item>
              <GatsbyImage
                image={
                  images.download_playstore.childImageSharp.gatsbyImageData
                }
                style={{ width: 140 }}
                className={"img"}
                alt="app screenshot"
                loading="eager"
              />
            </Grid>
          </Grid>
        </Hero>

        <div className={"info firstInfo desc"}>
          <Typography
            variant="h4"
            align="center"
            style={{ fontWeight: "bold" }}
          >
            {t("bite_description_title")}
          </Typography>
          <Typography paragraph align="center">
            {t("bite_description")}
          </Typography>
        </div>

        <div className={"info"} style={{ margin: "100px auto" }}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={10}
          >
            <Grid item sm={4} xs={6} className={"logo"}>
              <div className={"logo"}>
                <a href={brands["smashingBurgers"]?.biteLink}>
                  <GatsbyImage
                    image={
                      images.smashingburgersPoster.childImageSharp
                        .gatsbyImageData
                    }
                    className={"img burgerCursor"}
                    alt="Smashing Burgers logo"
                    loading="eager"
                  />
                  {false && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={"button"}
                    >
                      {t("Order now")}
                    </Button>
                  )}
                </a>
              </div>
            </Grid>
            <Grid item sm={4} xs={6} className={"logo"}>
              <div className={"logo"}>
                <a href={brands["pokiDoki"]?.biteLink}>
                  <GatsbyImage
                    image={
                      images.pokidokiPoster.childImageSharp.gatsbyImageData
                    }
                    className={"img pokeCursor"}
                    alt="Poki Doki logo"
                    loading="eager"
                  />
                  {false && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={"button"}
                    >
                      {t("Order now")}
                    </Button>
                  )}
                </a>
              </div>
            </Grid>
            <Grid item sm={4} xs={6} className={"logo"}>
              <div className={"logo"}>
                <a href={brands["chickNBuns"]?.biteLink}>
                  <GatsbyImage
                    image={
                      images.chicknbunsPoster.childImageSharp.gatsbyImageData
                    }
                    className={"img burgerCursor"}
                    alt="Chick n' Buns logo"
                    loading="eager"
                  />
                  {false && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={"button"}
                    >
                      {t("Order now")}
                    </Button>
                  )}
                </a>
              </div>
            </Grid>
            <Grid item sm={4} xs={6} className={"logo"}>
              <div className={"logo"}>
                <a href={brands["happyRoots"]?.biteLink}>
                  <GatsbyImage
                    image={
                      images.happyrootsPoster.childImageSharp.gatsbyImageData
                    }
                    className={"img saladCursor"}
                    alt="Happy Roots logo"
                    loading="eager"
                  />
                  {false && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={"button"}
                    >
                      {t("Order now")}
                    </Button>
                  )}
                </a>
              </div>
            </Grid>
            <Grid item sm={4} xs={6} className={"logo"}>
              <div className={"logo"}>
                <a href={brands["wonderWings"]?.biteLink}>
                  <GatsbyImage
                    image={
                      images.wonderwingsPoster.childImageSharp.gatsbyImageData
                    }
                    className={"img wonderCursor"}
                    alt="Wonder Wings logo"
                    loading="eager"
                  />
                  {false && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={"button"}
                    >
                      {t("Order now")}
                    </Button>
                  )}
                </a>
              </div>
            </Grid>
            <Grid item sm={4} xs={6} className={"logo"}>
              <div className={"logo"}>
                <a href={brands["tastySquare"]?.biteLink}>
                  <GatsbyImage
                    image={
                      images.tastysquarePoster.childImageSharp.gatsbyImageData
                    }
                    className={"img pizzaCursor"}
                    alt="Tasty Square logo"
                    loading="eager"
                  />
                  {false && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={"button"}
                    >
                      {t("Order now")}
                    </Button>
                  )}
                </a>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="info desc  ">
          <div>
            <Typography
              variant="h4"
              style={{ fontWeight: "bold" }}
              align="center"
            >
              {t("bite_foodCourt_title")}
            </Typography>
            <Typography align="center">{t("bite_foodCourt")}</Typography>
          </div>
        </div>

        <div className="info">
          <Typography variant="h4" align="center">
            {t("feed")}
          </Typography>

          <div className="socials">
            {false && (
              <a
                href="https://www.facebook.com/Bite-103660325001159"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={FB} height="32" width="32" alt="facebook logo"></img>
              </a>
            )}
            <a
              href="https://www.instagram.com/bitekitchens/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={IG} height="32" width="32" alt="instagram logo"></img>
            </a>
          </div>
          <Typography align="center">@bitekitchens</Typography>
        </div>
      </Layout>
    </ParallaxProvider>
  )
}

export default App
